import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PageBanner from "../components/PageBanner";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Icon from "../components/Icon";
import { useIsSafariBrowser } from "../hooks";

const colors = ["#0086B3", "#5AA79D", "#00396B"];

export const DonatePageTemplate = (props) => {
  const [iframeLoaded, setIframeLoaded] = React.useState(false);
  function handleLoaded() {
    setIframeLoaded(true);
  }
  const {
    description,
    heading,
    donationItems,
    image,
    iframeSrc,
    iframeHeight,
  } = props;

  const isSafari = useIsSafariBrowser();

  return (
    <>
      <PageBanner>
        <h1 className="page-banner-header">DONATE</h1>
      </PageBanner>
      <div className="layout-container">
        <div className="grid-container top-image-container">
          <div className="grid-item-2 grid-item donate-section">
            <div className="donation-section-body">
              <div className="donation-info-header">
                <span>{heading.toUpperCase()}</span>
              </div>
              <p>{description}</p>
              {donationItems.map(({ amount, description }, index) => {
                return (
                  <React.Fragment key={`donation-item-${index}`}>
                    <div className="donation-item">
                      <div>
                        <div
                          className="dollar-container"
                          style={{ color: colors[index] }}
                        >
                          <Icon iconName="badge" width={90} />
                          <span className="dollar-text">{amount}</span>
                        </div>
                      </div>
                      <div>
                        <p>
                          <strong>{description}</strong>
                        </p>
                      </div>
                    </div>
                    {index < 2 && <span className="donate-divider" />}
                  </React.Fragment>
                );
              })}
              <div className="body-img donation-img">
                <PreviewCompatibleImage imageData={image} />
              </div>
            </div>
          </div>
          <div className="grid-item-2 grid-item bg-white">
            <div>
              {isSafari ? (
                <div style={{ padding: 16 }}>
                  <h5>To donate, please use the link to our donation form</h5>
                  <div>
                    <a
                      href={iframeSrc}
                      className="btn primary-btn full-width"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ width: "100%" }}
                    >
                      GO TO DONATION FORM
                    </a>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="donate-form-header">
                    <p>Please complete the form below</p>
                  </div>
                  <iframe
                    title="Donation form"
                    style={{
                      width: "100%",
                      border: "none",
                      height: iframeHeight,
                      paddingTop: 18,
                      marginBottom: 60,
                    }}
                    src={iframeSrc}
                    onLoad={handleLoaded}
                  />
                  {iframeLoaded && (
                    <p className="text-caption">
                      Submitting your donation will automatically sign you up to
                      receive occasional news updates regarding Philips Academy.
                      You can unsubscribe at any time.
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

DonatePageTemplate.propTypes = {
  description: PropTypes.string,
  iframeSrc: PropTypes.string,
  iframeHeight: PropTypes.number,
  heading: PropTypes.string,
  donationItems: PropTypes.array,
  image: PropTypes.object,
};

const DonatePage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        description,
        iframeSrc,
        iframeHeight,
        heading,
        donationItems,
        image,
      },
    },
  } = data;
  return (
    <Layout
      pageTitle={`Donate`}
      ogUrl="/donate/"
      pageDecription="Philips Academy thrives with the help of donors like you! You can donate directly to Philips Academy through our web page."
    >
      <DonatePageTemplate
        description={description}
        iframeSrc={iframeSrc}
        iframeHeight={iframeHeight}
        heading={heading}
        donationItems={donationItems}
        image={image}
      />
    </Layout>
  );
};

DonatePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default DonatePage;

export const DonatePageQuery = graphql`
  query DonatePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        iframeSrc
        iframeHeight
        image {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
        donationItems {
          amount
          description
        }
      }
    }
  }
`;

import { useEffect, useState } from "react";

export function useIsSafariBrowser() {
  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    setHasRendered(true);
  }, []);

  return (
    hasRendered &&
    window.navigator.vendor &&
    window.navigator.vendor.indexOf("Apple") > -1
  );
}
